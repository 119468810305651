import React from "react"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import styled from "styled-components"

import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Seo from "../components/Seo"
import Layout from "../components/layout"
import Signature from "../images/Podpis.svg"

const useStyles = makeStyles(theme => ({
  grid: {
    display: "flex",
    minHeight: "calc(100vh - 494px)",
    marginTop: theme.spacing(15),
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(6)
    }
  },
  image: {
    border: "2px solid black"
  }
}))

const StudioPage = ({ data }) => {
  const classes = useStyles()
  return (
    <Layout logo="black">
      <Seo
        title={data.datoCmsStudioPage.seo?.title}
        description={data.datoCmsStudioPage.seo?.description}
        meta={data.datoCmsStudioPage.tags}
      />
      <Container maxWidth="lg">
        <Grid container spacing={6} className={classes.grid}>
          <Grid item sm={5} xs={12}>
            <GatsbyImage
              image={getImage(data.datoCmsStudioPage.avatar)}
              alt="O Nas"
              className={classes.image}
            />
          </Grid>
          <Grid item sm={7} xs={12}>
            <Typography
              component="div"
              variant="body1"
              dangerouslySetInnerHTML={{ __html: data.datoCmsStudioPage.content }}
            />
            <Box display="flex" justifyContent="flex-end">
              <StyledSignature />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}



const StyledSignature = styled(Signature)`
  margin-right: 0;
  margin-left: auto;

  width: 240px;

  @media (min-width: 600px) {
    width: 70%;
  }

  @media (min-width: 992px) {
    grid-row: 3;
    grid-column: 5;

    width: minmax(90%, 550px);
  }
`

export const query = graphql`
  query {
    datoCmsStudioPage {
      slogan
      content
      title
      avatar {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      image {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      seoMetaTags {
        tags
      }
      seo {
        title
        description
      }
    }
  }
`

export default StudioPage
